import mixpanel from "mixpanel-browser";
import { mixpanelEnv } from "../utils/endpoints";

const mixpanelPersistence = "localStorage";

export const mixpanelInit = () => {
  return mixpanel.init(mixpanelEnv!, {
    debug: true,
    track_pageview: true,
    persistence: mixpanelPersistence
  });
};
