"use client";
import { useState, useEffect } from "react";
import { parseCookies } from "nookies";
import { COOKIES } from "@nilo/constants";
import { SiteConfiguration } from "@nilo/codegen";

interface State {
  site?: SiteConfiguration;
  isLoaded?: boolean;
  date?: Date;
}

export const useSite = () => {
  const [state, setState] = useState<State>({});

  const setPrimaryColor = () => {
    const root = document.documentElement;
    root.style.setProperty("--primary-500", state?.site?.primaryPrincipalColor);
  };

  useEffect(() => {
    if (!state?.site?.primaryPrincipalColor) return;
    setPrimaryColor();
  }, [state?.site]);

  const mapperCookie = (cookie?: string) => {
    if (!cookie) return;
    const { site } = JSON.parse(cookie) as { site: SiteConfiguration };
    setState({ ...state, site, isLoaded: true });
  };

  const loadFromCookie = () => {
    const cookies = parseCookies();
    mapperCookie(cookies[COOKIES.siteConfiguration]);
  };

  useEffect(() => {
    loadFromCookie();
  }, []);

  return {
    ...state,
    loadFromCookie
  };
};
