export const getSubdomain = (
  url: string,
  defaultDomain: string = "localhost"
): string | null => {
  const localhostRegex = /^(?:http[s]?:\/\/)?(?:www\.)?localhost(?::\d+)?/i;

  if (localhostRegex.test(url)) return defaultDomain;

  const subdomainRegex = /^(?:http[s]?:\/\/)?([^.]+)\.(?:[^.]+\.)?[^.]+$/i;
  const matches = url.match(subdomainRegex);
  const subdomain = matches?.[1] || null;
  return subdomain;
};
