import { mixpanelInit } from "./init";
import {
  identifyUser,
  resetUser,
  setUserProperties,
  trackCategories
} from "./track";

type TrackCategories = typeof trackCategories;
type CategoryKeys = keyof TrackCategories;
type TrackFunctions<T extends keyof TrackCategories> = TrackCategories[T];

export const useAnalytics = <T extends CategoryKeys>(category: T) => {
  let isMixpanelInitialized = false;

  const analyticsInit = () => {
    if (isMixpanelInitialized) return;

    mixpanelInit();
    isMixpanelInitialized = true;
  };

  const track: TrackFunctions<T> = trackCategories[category];

  return {
    analyticsInit,
    track,
    identify: identifyUser,
    reset: resetUser,
    setUserProperties
  };
};
